// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../../../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-about-site-tsx": () => import("./../../../src/pages/about-site.tsx" /* webpackChunkName: "component---src-pages-about-site-tsx" */),
  "component---src-pages-archives-tsx": () => import("./../../../src/pages/archives.tsx" /* webpackChunkName: "component---src-pages-archives-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-tsx": () => import("./../../../src/pages/life.tsx" /* webpackChunkName: "component---src-pages-life-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-subject-tsx": () => import("./../../../src/pages/subject.tsx" /* webpackChunkName: "component---src-pages-subject-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-subjects-tsx": () => import("./../../../src/templates/subjects.tsx" /* webpackChunkName: "component---src-templates-subjects-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

